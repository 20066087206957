import Autosuggest from 'react-autosuggest';
//import {AutosuggestHighlightMatch, AutosuggestHighlightParse} from 'react-autosuggest';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import '../styles/search.scss';

const Search = ({ closeModal, mobile }) => {
	const people = [];
	const navigate = useNavigate()

	const [suggestions, setSuggestions] = useState([]);
	const [value, setValue] = useState('');

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
  };

  const checkEnter = (event) => {
    if(event.key === 'Enter'){
      event.preventDefault();
      goToSearch();
    }
  };

  const goToSearch = () => {
  	navigate('/search/' + value)
    closeModal();
  }

	const inputProps = {
	  placeholder: "Buscar...",
	  value,
	  onChange: onChange,
	  type: 'search',
	  required: 'required',
	  onKeyDown: checkEnter
	};

	const escapeRegexCharacters = (str) => {
	  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};

  const getSuggestionValue = (suggestion) => {
    return `${suggestion.first} ${suggestion.last}`;
  };

	const getSuggestions = (value) => {
	  const escapedValue = escapeRegexCharacters(value.trim());
	  
	  if (escapedValue === '') {
	    return [];
	  }

	  const regex = new RegExp('\\b' + escapedValue, 'i');
	  
	  return people.filter(person => regex.test(getSuggestionValue(person)));
	}

	const renderSuggestion = (suggestion, query) => {
	  const suggestionText = `${suggestion.first} ${suggestion.last}`;
	  return (
	    <span className={'suggestion-content ' + suggestion.twitter}>
	      <span className="name">
	      	{suggestionText}
	      </span>
	    </span>
	  );
	}

  const onSuggestionsFetchRequested = (value) => {
    setSuggestions(getSuggestions(value.value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  return (
	  <div className="search-modal">
	    <h1>{mobile ? "Buscar" : "Buscar en Copec"}</h1>
	    <Autosuggest 
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      {mobile && value !== '' &&
      	<button onClick={goToSearch} className="mobile-search-button" />
    	}
	  </div>
  );
};

export default Search;
