import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const PrivateRoute = ({path}) => {
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();
  
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await signinRedirect();
    };
    fn();
  }, [isLoading, isAuthenticated, signinRedirect, path]);

  return isAuthenticated? <Outlet /> : null;

}
export default PrivateRoute;