import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import usuario from '../assets/img/user_account_icon.png';
import userWhite from '../assets/img/user_white.png';
import backArrow from "../assets/img/arrow_back_blue.svg";
import Loader from "./Loader";
import SuccessIcon from "../assets/img/success-icon.png";
import '../styles/my-account.scss';

const MyAccount = () => {
  const { isLoading, user, isAuthenticated } = useAuth();
  const [ showLoader, setShowLoader ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ token, setToken ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ phone, setPhone ] = useState();
  const [ yearsInCopec, setYearsInCopec ] = useState('');

  const [ partnerName, setPartnerName ] = useState('');
  const [ partnerMail, setPartnerMail ] = useState('');

  const [ partnerBornDateDay, setPartnerBornDateDay ] = useState('');
  const [ partnerBornDateMonth, setPartnerBornDateMonth ] = useState('');
  const [ partnerBornDateYear, setPartnerBornDateYear ] = useState('');

  const [ partnerWorksInEDS, setPartnerWorksInEDS ] = useState();
  const [ partnerTelefonoMovil, setPartnerTelefonoMovil ] = useState();
  const [ legalRepresentative, setLegalRepresentative ] = useState({});
  const [ fechaIngreso, setFechaIngreso ] = useState();
  const [ eds, setEds ] = useState([]);
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth);
  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if(user && isAuthenticated){
      setToken(user.access_token)
      getImpersonate(user.access_token)
    }
  }, [user]);

  if (isLoading || !user) {
    return <div>Loading...</div>;
  }

  async function getImpersonate(token){
    let url = process.env.REACT_APP_BASE_URL + 'identities/impersonate'
    await fetch(url, { headers: { 'token': token }})
      .then(res => res.json())
      .then((result) => {
        let email
        if(result.code === 200){
          email = result.data[0].email
        }
        else{
          email = user.email;
        }
        setEmail(email);
        getUser(token, email);
        getAccount(token, email);
      },
      (error) => {
        const email = user.email;
        setEmail(email);
        getUser(token, email);
        getAccount(token, email);
      }
    )
  }



  function goBack(){
    navigate(-1)
  }

  async function getAccount(newToken = null, newEmail = null){
    if(!newToken){
      newToken = token;
    }
    if(!newEmail){
      newEmail = email;
    }
    await fetch(process.env.REACT_APP_IDENTITIES_URL + '/account?id=' + newEmail, { headers: { token: newToken } })
    .then((res) => res.json())
    .then(
      (result) => {
        if(result.data && result.data[0]){
          setCurrentUser(result.data[0].metadata);
          setDefaults(result.data[0].metadata);
        }
      },
      (error) => {
        alert(error);
      }
    );
    setShowLoader(false);
  }

  function getUser(newToken = null, newEmail = null){
    if(!newToken){
      newToken = token;
    }
    if(!newEmail){
      newEmail = email;
    }
    fetch(process.env.REACT_APP_IDENTITIES_URL + '/users?id=' + newEmail, { headers: { token: newToken } })
    .then((res) => res.json())
    .then(
      (result) => {
        if(result.data && result.data[0]){
          getConcesionario(result.data[0].rut_concesionario, newToken);
        }
      },
      (error) => {
        alert(error);
      }
    );
  }

  function getConcesionario(rutConcesionario, newToken){
    fetch(process.env.REACT_APP_CONCESIONARIOS_URL + '?id=' + rutConcesionario, { headers: { token: newToken } })
    .then((res) => res.json())
    .then(
      (result) => {
        if(rutConcesionario === '77215640-5'){ // IF ITS ARCOPRIME
          const arcoprime = result.data.concesionarios.find(e => e.id === '0000715204');
          setLegalRepresentative(arcoprime.representantes_legales[0]);
          getYearsInCopec(arcoprime.fecha_ingreso);
          setFechaIngreso(arcoprime.fecha_ingreso);
          if(!phone){
            setPhone(arcoprime.representantes_legales[0].telefono_movil);
          }
        }
        else{
          if(result.data.concesionarios){
            setLegalRepresentative(result.data.concesionarios[0].representantes_legales[0]);
            getYearsInCopec(result.data.concesionarios[0].fecha_ingreso);
            setFechaIngreso(result.data.concesionarios[0].fecha_ingreso);
            if(!phone){
              setPhone(result.data.concesionarios[0].representantes_legales[0].telefono_movil);
            }
          }
        }

        if(result.data.concesionarios){
          let currEds = [];
          result.data.concesionarios.forEach(function(concesionario){
            currEds = currEds.concat(concesionario.eds); 
          })

          setEds(currEds);
        }

      },
      (error) => {
        alert(error);
      }
    );
  }

  function setDefaults(thisCurrentUser = null){
    setIsEditing(false);

    if(!thisCurrentUser){
      thisCurrentUser = currentUser;
    }

    setPartnerName(thisCurrentUser.partner_name);
    setPartnerMail(thisCurrentUser.partner_mail);

    setPartnerBornDateDay(getDateDay(thisCurrentUser.partner_born_date));
    setPartnerBornDateMonth(getDateMonth(thisCurrentUser.partner_born_date));
    setPartnerBornDateYear(getDateYear(thisCurrentUser.partner_born_date));
    setPartnerTelefonoMovil(thisCurrentUser.partner_telefono_movil);

    const partnerWorksInEdsTmp = thisCurrentUser.partner_works_in_eds === 'Si'? true: false;

    setPartnerWorksInEDS(partnerWorksInEdsTmp);
  }

  function getDateDay(str){
    if(!str){
      return '';
    }
    return(str.substring(6, 8));
  }

  function getDateMonth(str){
    if(!str){
      return '';
    }
    return(str.substring(4, 6));
  }

  function getDateYear(str){
    if(!str){
      return '';
    }
    return(str.substring(0, 4));
  }

  function getShortEds(eds_id){
    if(eds_id.length > 5){
      eds_id = eds_id.substring(eds_id.length - 5)
    }
    return eds_id
  }

  async function saveUser(){
    setShowLoader(true);
    const updatedAccount = {
      telefono_movil: phone,
      partner_name: partnerName,
      partner_mail: partnerMail,
      partner_born_date: partnerBornDateYear.toString() + partnerBornDateMonth.toString() + partnerBornDateDay.toString(),
      partner_works_in_eds: partnerWorksInEDS? 'Si': 'No',
      partner_telefono_movil: partnerTelefonoMovil
    }

    const body = {
      "metadata": updatedAccount
    };

    await fetch(process.env.REACT_APP_IDENTITIES_URL + '/account?id=' + email, {
      method: "PUT",
      headers: {
        token: token,
      },
      body: JSON.stringify(body),
    }).then(
      (res) => {
        if(res.status === 200){
          setLegalRepresentative({...legalRepresentative, telefono_movil: phone});
        }
        getAccount();
        getUser();

        if(width < breakpoint){
          setShowSuccess(true)
          window.scrollTo(0, 0)
        }
      },
      (error) => {
        alert(error);
      }
    );

    setIsEditing(false);
  }

  function getDate(str){
    if(str){
      return str.substring(6, 8) + '/' + str.substring(4, 6) + '/' + str.substring(0, 4);
    }

    return '';
  }

  function getYearsInCopec(start_date){
    if(start_date){
      const start = new Date(start_date.substring(0, 4), start_date.substring(4, 6), start_date.substring(6, 8));
      const diff = Date.now() - start;
      const ageDate = new Date(diff);
      const totalYears = Math.abs(ageDate.getUTCFullYear() - 1970);

      if(totalYears < 1000){
        setYearsInCopec(totalYears);
      }
    }
  }

  const inputTypeText = ({ currentData, updateData }) => (
    <input type="text" value={currentData} onChange={ (e) => updateData(e.target.value) } />
  )

  const inputTypeDate = ({ currentData, updateData }) => (
    <div className="account-dates">
      <input type="text" maxLength="2" value={ currentData.day } onChange={ (e) => updateData.day(e.target.value) }/>
      <input type="text" maxLength="2" value={ currentData.month } onChange={ (e) => updateData.month(e.target.value) }/>
      <input className="year" type="text" maxLength="4" value={ currentData.year } onChange={ (e) => updateData.year(e.target.value) }/>
    </div>
  )

  const inputTypeRadio = ({ currentData, updateData }) => (
    <div className="radio-button">
      <input type="radio" name="works-in-eds" value="true" checked={ currentData } onChange={ () => updateData(true)} /> 
      <span>Si</span>
      <input type="radio" name="works-in-eds" value="false" checked={ !currentData } onChange={ () => updateData(false)} />
      <span>No</span>
    </div>
  )

  const selectInputType = (type) => {
    switch (type) {
      case "date":
        return inputTypeDate;
      case "radio":
        return inputTypeRadio;
      default:
        return inputTypeText;
    }
  }

  const showData = (data) => (
    <>
      <div className="row data">
        <div className="left">
          <div className="data-title">{data[0].title}</div>
          { data[0].edit && isEditing
            ? selectInputType(data[0].type)(
              {
                currentData: data[0].currentData,
                updateData: data[0].updateData
              })
            : <div className="data-info">
                { data[0].info }
              </div>
          }
        </div>
        <div className="right">
          <div className="data-title">{data[1].title}</div>
          { data[1].edit && isEditing
            ? selectInputType(data[1].type)(
              {
                currentData: data[1].currentData,
                updateData: data[1].updateData
              })
            : <div className="data-info">
                { data[1].info }
              </div>
          }
        </div>
      </div>
      <hr />
    </>
  )

  const showTitle = (icon, title, subtitle) => (
    <div className="row">
      <div className="left">
        <img src={icon} alt='icono-usuario' />
      </div>
      <div className="right">
        <div className="title section-title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  )

  return (
    <div className={`my-account${width > breakpoint? " row": ""}`}>
      { showLoader && <Loader /> }
      <div className="column-left">
        <img src={backArrow} className="back-button-mobile" onClick={goBack} />
        <div className='column-left-content'>
          {showTitle(userWhite, "Mis datos", "Modifica tus datos de usuario")}
        </div>
      </div>

      <div className="column-right">
        <div className="section-info">
          {showTitle(usuario, "Mi cuenta", "Mis datos")}
        </div>
        
        <hr />

        { showSuccess && 
          <div className="action-message">
            <img src={SuccessIcon} />
            <div className="title">Tus nuevos datos</div>
            <div className="message">¡se han guardado exitosamente!</div>
            <hr />
            <button className="save" onClick={() => setShowSuccess(false)}>Aceptar</button>
          </div>
        }
        
        { !showSuccess && 
          <>
            <div>
              <div className="data-info">
                {width > breakpoint ? 
                  <>
                    <table className='my-account-table'>
                      <thead>
                        <tr>
                          <td>ESTACIÓN DE SERVICIO</td>
                          <td>JEFE DE ZONA</td>
                          <td>JEFE COMERCIAL</td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          eds.map((eds, key) => 
                            <>
                              { eds.active &&
                                <tr key={ key }>
                                  <td>{ getShortEds(eds.eds_id) }{!eds.active && ' (No activa)'}</td>
                                  <td>{ eds.metadata && eds.metadata.jefe_zona }</td>
                                  <td>{ eds.metadata && eds.metadata.jefe_comercial }</td>
                                </tr>
                              }
                            </>
                          )
                        }
                      </tbody>
                    </table>
                  </>
                  : <>
                    <div>
                      <div className="data-title">Estaciones de servicio</div>
                      <ul>
                      {
                        eds.map((eds, key) => 
                          <li className="data-info" key={ key }>
                            <span>EDS { eds.eds_id }{!eds.active && ' (No activa)'}</span>
                            <div className="eds-list-data">Jefe de zona: { eds.metadata && eds.metadata.jefe_zona }</div>
                            <div className="eds-list-data">Jefe comercial: { eds.metadata && eds.metadata.jefe_comercial }</div>
                          </li>
                        )
                      }
                      </ul>

                    </div>
                  </>
                }
              </div>

              <hr />
            
              {width > breakpoint &&
                showData([
                  {title: "SOY EL REPRESENTANTE LEGAL", info: "Si", edit: false},
                  {title: "", info: "", edit: false}])}
            </div>
        

            { width > breakpoint
            ? 
              <>
                {[
                  [
                    {title: "NOMBRE", info: legalRepresentative.nombre, edit: false},
                    {title: "APELLIDO", info: legalRepresentative.apellidos, edit: false}
                  ],
                  [
                    {title: "FECHA DE NACIMIENTO", info: getDate(legalRepresentative.cumpleaños), edit: false},
                    {title: "RUT", info: legalRepresentative.rut, edit: false}
                  ],
                  [
                    {title: "MAIL", info: email, edit: false},
                    {
                      title: "TELÉFONO",
                      info: legalRepresentative.telefono_movil,
                      edit: true,
                      currentData: phone,
                      updateData: setPhone
                    }
                  ],
                  [
                    {title: "FECHA INGRESO COPEC", info: getDate(fechaIngreso), edit: false},
                    {title: "AÑOS EN COPEC", info: yearsInCopec, edit: false}
                  ]
                ].map(showData)}

                <div className="section-info">
                  {showTitle(usuario, "Mi cuenta", "Datos pareja")}
                </div>
                <hr />

                {[
                  [
                    {
                      title: "NOMBRE COMPLETO",
                      info: currentUser.partner_name,
                      edit: true,
                      currentData: partnerName,
                      updateData: setPartnerName
                    },
                    {
                      title: "MAIL",
                      info: currentUser.partner_mail,
                      edit: true,
                      currentData: partnerMail,
                      updateData: setPartnerMail
                    }
                  ],
                  [
                    {
                      title: "FECHA DE NACIMIENTO",
                      info: getDate(currentUser.partner_born_date),
                      edit: true,
                      type: "date",
                      currentData: {
                        day: partnerBornDateDay,
                        month: partnerBornDateMonth,
                        year: partnerBornDateYear
                      },
                      updateData: {
                        day: setPartnerBornDateDay,
                        month: setPartnerBornDateMonth,
                        year: setPartnerBornDateYear
                      }
                    },
                    {
                      title: "PAREJA TRABAJA EN EDS",
                      info: currentUser.partner_works_in_eds,
                      edit: true,
                      type: "radio",
                      currentData: partnerWorksInEDS,
                      updateData: setPartnerWorksInEDS
                    }
                  ],
                  [
                    {
                      title: "TELÉFONO",
                      info: currentUser.partner_telefono_movil,
                      edit: true,
                      currentData: partnerTelefonoMovil,
                      updateData: setPartnerTelefonoMovil
                    },
                    {title: "", info: "", edit: false}
                  ]
                ].map(showData)}
              </>
            : <>
                {[
                  [
                    {title: "FECHA DE NACIMIENTO", info: getDate(legalRepresentative.cumpleaños), edit: false},
                    {title: "RUT", info: legalRepresentative.rut, edit: false}
                  ],
                  [
                    {title: "MAIL", info: email, edit: false},
                    {
                      title: "TELÉFONO",
                      info: legalRepresentative.telefono_movil,
                      edit: true,
                      currentData: phone,
                      updateData: setPhone
                    }
                  ],
                  [
                    {title: "FECHA INGRESO COPEC", info: getDate(fechaIngreso), edit: false},
                    {title: "AÑOS EN COPEC", info: yearsInCopec, edit: false}
                  ]
                ].map(showData)}
                <div className="section-info">
                  {showTitle(usuario, "Mi cuenta", "Datos pareja")}
                </div>
                <hr />
                {[
                  [
                    {
                      title: "NOMBRE COMPLETO",
                      info: currentUser.partner_name,
                      edit: true,
                      currentData: partnerName,
                      updateData: setPartnerName
                    },
                    {
                      title: "MAIL",
                      info: currentUser.partner_mail,
                      edit: true,
                      currentData: partnerMail,
                      updateData: setPartnerMail
                    }
                  ],
                  [
                    {
                      title: "FECHA DE NACIMIENTO",
                      info: getDate(currentUser.partner_born_date),
                      edit: true,
                      type: "date",
                      currentData: {
                        day: partnerBornDateDay,
                        month: partnerBornDateMonth,
                        year: partnerBornDateYear
                      },
                      updateData: {
                        day: setPartnerBornDateDay,
                        month: setPartnerBornDateMonth,
                        year: setPartnerBornDateYear
                      }
                    },
                    {
                      title: "PAREJA TRABAJA EN EDS",
                      info: currentUser.partner_works_in_eds,
                      edit: true,
                      type: "radio",
                      currentData: partnerWorksInEDS,
                      updateData: setPartnerWorksInEDS
                    }
                  ]
                ].map(showData)}
              </>
            }
            
            <div className="my-account-message">
              <span className="asterisk">Importante: </span>
              <span>Cualquier duda con tus datos, favor contactar a </span>
              <span className="email">conectados@copec.cl</span>
            </div>


            { !isEditing &&
              <button className="edit" onClick={ () => setIsEditing(true) }>Editar datos</button>
            }
            {
              isEditing && 
              <div>
                <button className="cancel" onClick={ () => setDefaults() }>Cancelar</button>
                <button className="save" onClick={ () => saveUser() }>Guardar</button>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default MyAccount;