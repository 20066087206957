import React from "react";
import { Link } from "react-router-dom";
import '../styles/breadcrumbs.scss';

const Breadcrumbs = (props) => {
  const menu = props.menu;
  let menuFirstLevel = props.menuFirstLevel;
  let menuSecondLevel = props.menuSecondLevel;
  let menuThirdLevel = props.menuThirdLevel;
  const iframeSrc = props.iframeSrc;
  const parentPath = iframeSrc.substring(0, iframeSrc.lastIndexOf("/") + 1);

  if((!menuFirstLevel || !menuSecondLevel || !menuThirdLevel) && menu && Object.keys(menu).length > 0){
    Object.keys(menu).forEach((fl) => 
      Object.keys(menu[fl].children).forEach((sl) => 
        Object.keys(menu[fl].children[sl].children).forEach((tl) => {
          if(parentPath === menu[fl].children[sl].children[tl].componentUrl){
            menuFirstLevel = fl;
            menuSecondLevel = sl;
            menuThirdLevel = tl;
          }
        })
      )
    )
  }

  return (
    <div className="breadcrumbs">
      <Link to="/">Home</Link>
      { menuFirstLevel && menuSecondLevel && menuThirdLevel &&
        <span>
          <span className="menu-path"> / { menuFirstLevel }</span>
          <span className="menu-path"> / { menuSecondLevel }</span>
          <span className="menu-path"> / <b>{ menuThirdLevel }</b></span>
        </span>
      }
    </div>
  );
};

export default Breadcrumbs;
