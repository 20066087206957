import React from "react";
import '../styles/loader.scss';
import camion from "../assets/img/camion-loader.svg";

const Loader = () => {
  return (
    <div className='loader-container'>
      <div className='loader'>
      	<object type="image/svg+xml" data={camion}></object>
      </div>
    </div>
  );
};

export default Loader;

