import React, { useState, useEffect, useRef } from "react";
import NavBar from "./components/NavBar";
import NavBarMobile from "./components/NavBarMobile";
import Footer from "./components/Footer";
import { useAuth, hasAuthParams } from "react-oidc-context";

import { Router, Routes, Route, useNavigate } from "react-router-dom";
import Profile from "./components/Profile";
import MyAccount from "./components/MyAccount";
import Report from "./components/Report";
import MicroComponent from "./components/MicroComponent";
import { getPath } from "./utils/getPath.js";
import { getEmailFromToken, getTokenExpiry } from "./utils/getEmailFromToken.js";
import PrivateRoute from "./components/PrivateRoute";
import Loader from "./components/Loader";
import Home from "./components/Home";
import EmptyMenu from "./components/EmptyMenu";
import SearchResults from './components/SearchResults';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import "./styles/App.scss";
import closeIcon from "./assets/img/cross-icon.svg";
import importante from "./assets/img/importante.png";
import { Alert } from 'pharedata-custom-components';
import TagManager from 'react-gtm-module'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : 'calc(50% - 140px)',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

function App() {
  const { isAuthenticated, signinRedirect, signoutRedirect, removeUser, user: fusionauthUser, activeNavigator, isLoading } = useAuth();
  const [ hasTriedSignin, setHasTriedSignin ] = useState(false);
  const [ menu, setMenu ] = useState({});
  const [ filteredMenu, setFilteredMenu ] = useState({});
  const [ token, setToken ] = useState();
  const [ showLoader, setShowLoader ] = useState(false);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ impersonatedUser, setImpersonatedUser ] = useState();
  const [ isROImpersonate, setIsROImpersonate ] = useState(false);
  const [ showMyAccount, setShowMyAccount ] = useState(false);
  const [ width, setWidth ] = useState(window.innerWidth);
  const [ user, setUser ] = useState();
  const [ stopImpersonating, setStopImpersonating ] = useState(false);
  const [ pagadorArcoprime, setPagadorArcoprime ] = useState()
  const [ displayCustomAlert, setDisplayCustomAlert ] = useState(false)
  const [ alertText, setAlertText ] = useState('')
  const [ alertTitle, setAlertTitle ] = useState('')
  const [ alertIcon, setAlertIcon ] = useState('')
  const [ permissionError, setPermissionError ] = useState(false)
  const [ fAEmail, setFAEmail ] = useState("")
  
  const navigate = useNavigate()

  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT)

  const pageYOffset = window.pageYOffset;

  const stopImpersonatingRef = useRef(false)

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setToken(fusionauthUser.access_token)
      const exp = getTokenExpiry(fusionauthUser.access_token)
      setTimeout(function(){
        checkTokenStatus(exp) 
      }, 5000)

      const email = fusionauthUser.profile.email
      setFAEmail(email)
      getUser(fusionauthUser.access_token, email)
      getImpersonate(fusionauthUser.access_token)
      getPagadorArcoprime(fusionauthUser.access_token)
    }
    else if(!isAuthenticated && !hasTriedSignin && !hasAuthParams() && !isLoading && !activeNavigator){
      signinRedirect()
      setHasTriedSignin(true)
    }
  }, [isAuthenticated, hasTriedSignin, fusionauthUser]);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, true)
  }, [menu])

  useEffect(() => {
    window.addEventListener("resize", orientationChange, true)
  }, [])

  useEffect(() => {
    if(user && !impersonatedUser){
      initializeTagManager()
    }
  }, [user])

  function initializeTagManager(){
    let customUserId

    if(user.metadata){
      customUserId = user.metadata.email.replace(/\W/g, '')
    }
    else if(user.PK){
      customUserId = user.PK.split("#")[1].replace(/\W/g, '')
    }
    else{
      console.log("Usuario vacio, se llama nuevamente a endpoint")
      getUser(token, fAEmail)
      getImpersonate(token)
      getPagadorArcoprime(token)
      return
    }

    if(!customUserId.includes("monitor_portal")){
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_TAG_MANAGER,
        dataLayer: {
          user_id: customUserId,
          event: 'userIdSet'
        }
      }

      if(user.rut_concesionario){
        if(user.rut_concesionario === "77215640-5") { // If is arcoprime
          if(!user.groups.includes(process.env.REACT_APP_ADMIN_CONCESIONARIO_GROUP_ID)){ //ES USER EDS
            const url = process.env.REACT_APP_PEDIDOS_API + "/pedidos/eds-portal";

            fetch(url, { 
              method: 'GET',    
              headers: { 
                'token': token,
                'Content-Type': 'application/json'
              }
            })
            .then(res => res.json())
            .then((result) => {
              const eds = result.data.user_eds

              tagManagerArgs.dataLayer.eds = eds.toString()
              tagManagerArgs.dataLayer.user_id = tagManagerArgs.dataLayer.user_id + eds.toString()
              TagManager.initialize(tagManagerArgs)
              },
              (error) => { console.log(error) }
            )
          }
          else{
            tagManagerArgs.dataLayer.eds = "N/A"
            tagManagerArgs.dataLayer.user_id = tagManagerArgs.dataLayer.user_id + "NA"
            TagManager.initialize(tagManagerArgs)

          }
        }

        else{ //SI NO ES ARCOPRIME
          const url = process.env.REACT_APP_PEDIDOS_API + "/pedidos/obtener-instalaciones-cliente?id_pagador=" + user.id_concesionario;

          fetch(url, { 
            method: 'GET',    
            headers: { 
              'token': token,
              'Content-Type': 'application/json'
            }
          })
          .then(res => res.json())
          .then((result) => {
            const eds = []
            result.data.INSTALACIONES.forEach(function(instalacion){
              eds.push(instalacion.ID_EDS)
            })

            tagManagerArgs.dataLayer.eds = eds.toString()
            tagManagerArgs.dataLayer.user_id = tagManagerArgs.dataLayer.user_id + eds.toString()
            TagManager.initialize(tagManagerArgs)
            },
            (error) => { console.log(error) }
          )
        }
      }

      else{
        tagManagerArgs.dataLayer.eds = "N/A"
        tagManagerArgs.dataLayer.user_id = tagManagerArgs.dataLayer.user_id + "N/A"
        TagManager.initialize(tagManagerArgs)
      }
    }
  }

  function closeCustomAlert(){
    setDisplayCustomAlert(false)
    if(permissionError){
      setPermissionError(false)
      removeUser()
      signoutRedirect()
    }
  }

  const orientationChange = () => {
    //document.documentElement.style.height = `initial`;
    setTimeout(() => {
      //document.documentElement.style.height = `100%`;
      document.documentElement.style.width = `100vw`;
      document.documentElement.style.overflow = `auto`;
        
        /* No se porque existia este scroll, pero hace que escrolee hacia arriba a cada rato en mobile
        setTimeout(() => {
          // this line prevents the content
          // from hiding behind the address bar
          

          console.log('scroll por orientation change?')
          window.scrollTo(0, 0);
        }, 500);
        */

    }, 500);
  }

  const receiveMessage = (event) => {
    const message = event.data;

    if (message.checkIframeSrc) {
      if(Object.keys(menu).length === 0)
        return

      let urlArr = window.location.href.split("?")[0]
      urlArr = urlArr.split("/")
      
      const level3 = urlArr[urlArr.length - 1]
      const level2 = urlArr[urlArr.length - 2]
      const level1 = urlArr[urlArr.length - 3]

      Object.keys(menu).forEach((key) =>
        Object.keys(menu[key].children).forEach((menukey) =>
          Object.keys(menu[key].children[menukey].children).forEach((menukeylast) => {
            const path=getPath(key, menukey, menukeylast)
            const currentPath = `/${level1}/${level2}/${level3}`

            if(path === currentPath){
              const bucketPathCurr = menu[key].children[menukey].children[menukeylast].componentUrl
              if(bucketPathCurr !== message.checkIframeSrc && process.env.REACT_APP_ENVIRONMENT !== 'dev'){
                navigate(-1)
              }
            }
      })))
    }

    if(message.disableScrolling){
      disableScrolling()
    }

    if(message.enableScrolling){
      enableScrolling()
    }
  }

  async function getUser(token, email, isImpersonating = false){
    let url = process.env.REACT_APP_BASE_URL + 'identities/users';
    if(email){
      url = url + '?id=' + encodeURIComponent(email);
    }

    await fetch(url, { headers: { 'token': token }})
      .then(res => res.json())
      .then((result) => {
        if(result.code !== 200){
          if(isImpersonating){
            removeImpersonate(token)
            setAlertText("Este usuario no tiene permisos para acceder al portal. Se dejará de suplantar.")
            setAlertTitle("Importante")
            setAlertIcon("importante")
            setShowLoader(false)
            setDisplayCustomAlert(true)
          }
          else{
            setAlertText("Usted no tiene permiso para acceder al Portal, contacte al administrador.")
            setAlertTitle("Importante")
            setAlertIcon("importante")
            console.log("setting show loader false")
            setShowLoader(false)
            setPermissionError(true)
            setDisplayCustomAlert(true)
          }
          return;
        }
        else{
          const tmpUser = result.data[0]? result.data[0]: {}
          const groups = []
          result.data.forEach(function(d){
            if(d.SK.includes("GROUP")){
              groups.push(d.SK)
            }
          })

          tmpUser.groups = groups

          setUser(tmpUser)
          if(result.data[0] && result.data[0].metadata && result.data[0].metadata.is_legal_representative){
            setShowMyAccount(true);
          }
          let url = process.env.REACT_APP_BASE_URL + "menus/menu_items";
          fetch(url, { headers: { token: token } })
            .then((res) => res.json())
            .then(
              (result) => {
                if (result.code !== 200) {
                  setAlertText("Ha ocurrido un error. Por favor contáctate con el administrador.")
                  setAlertTitle("Error")
                  setAlertIcon("error")
                  setDisplayCustomAlert(true)
                }
                if (JSON.stringify(menu) !== JSON.stringify(result.data)) {
                  if (result.data) {
                    setMenu(result.data);

                    const newMenu = {}

                    Object.keys(result.data).forEach(key => {
                      if(Object.keys(result.data[key].children).length === 0 || !result.data[key].published)
                        return

                      Object.keys(result.data[key].children).forEach(secondKey => {

                        if(Object.keys(result.data[key].children[secondKey].children).length === 0 || !result.data[key].children[secondKey].published)
                          return

                        Object.keys(result.data[key].children[secondKey].children).forEach(thirdKey => {
                          
                          if(width <= breakpoint){
                            if(result.data[key].hidelowres
                              || result.data[key].children[secondKey].hidelowres 
                              || result.data[key].children[secondKey].children[thirdKey].hidelowres
                            )
                              return
                          }

                          if(!result.data[key].children[secondKey].children[thirdKey].published)
                            return

                          if(!newMenu[key]){
                            newMenu[key] = {...result.data[key]}
                            newMenu[key].children = {}
                          }

                          if(!newMenu[key].children[secondKey]){
                            newMenu[key].children[secondKey] = {...result.data[key].children[secondKey]}
                            newMenu[key].children[secondKey].children = {}
                          }
                          newMenu[key].children[secondKey].children[thirdKey] = result.data[key].children[secondKey].children[thirdKey]
                        })
                      })
                    })

                    setFilteredMenu(newMenu)
                  }
                }
              },
              (error) => {
                setAlertText("Ha ocurrido un error. Por favor contáctate con el administrador.")
                setAlertTitle("Error")
                setAlertIcon("error")
                setDisplayCustomAlert(true)
              }
            );
          }
      },
      (error) => {
        setAlertText("Usted No tiene permiso para acceder al Portal, contacte al administrador.")
        setAlertTitle("Importante")
        setAlertIcon("importante")
        setDisplayCustomAlert(true)
        signinRedirect()
        /*
        signinRedirect({
          redirect_uri: {targetUrl: window.location.pathname}
        });
        */

      }
    )
  }

  async function getImpersonate(token){
    if(stopImpersonating === false){
      let url = process.env.REACT_APP_BASE_URL + 'identities/impersonate';

      await fetch(url, { headers: { 'token': token }})
        .then(res => res.json())
        .then((result) => {
          if(result.code === 200){
            if(result.userMessage !== "Suplantacion no activa"){
              const email = result.data[0].email
              setImpersonatedUser(email)

              if(result.data[0].type && result.data[0].type === 'RO'){
                setIsROImpersonate(true)
              }

              getUser(token, email, true)
              setTimeout(function(){
                checkImpersonateStatus(token); 
              }, 5000);
            }
          }
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function checkImpersonateStatus(token, refff = null){
    if(!stopImpersonating && !refff){
      let url = process.env.REACT_APP_BASE_URL + 'identities/impersonate';
      fetch(url, { headers: { 'token': token }})
        .then(res => res.json())
        .then((result) => {
          if(result.code === 200){
            setTimeout(function(){
              checkImpersonateStatus(token, stopImpersonatingRef.current)
            }, 5000);
          }
          else{
            setImpersonatedUser()
            window.location.href = "/"
          }
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function checkTokenStatus(exp){
    const seconds = Math.floor(Date.now() / 1000)
    if(exp < seconds){
      removeUser()
      signoutRedirect()
    }
    else{
      setTimeout(function(){
        checkTokenStatus(exp) 
      }, 5000)
    }
  }

  function removeImpersonate(newToken = null){
    setStopImpersonating(true)
    stopImpersonatingRef.current = true

    if(!newToken || newToken === null){
      newToken = token
    }

    setShowLoader(true)

    let url = process.env.REACT_APP_BASE_URL + 'identities/impersonate'
    
    fetch(url, 
      { 
        method: 'DELETE',    
        headers: { 
          'token': newToken,
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then((result) => {
        //Se espera 1 segundo antes del reload para que se alcance a actualizar el usuario y se cargue el menu
        setTimeout(() => {
          if(isROImpersonate){
            window.location.href = process.env.REACT_APP_SUPLANTACION_RO_PATH
          }
          else{
            window.location.href = process.env.REACT_APP_SUPLANTACION_PATH
          }
        }, 2000);
      },
      (error) => {
        console.log(error)
        setShowLoader(false)
      }
    )
  }

  function iframeClicked(){
    const closingMenu = document.getElementById("hidden-button-for-closing-menu");
    if (closingMenu) closingMenu.click();
  }

  function closeModal(){
    setModalIsOpen(false)
  }

  function scrollTo(x, y){
    window.scrollTo(x, y)
  }

  function disableScrolling(){
    var element = document.getElementsByTagName("html")[0]
    element.classList.add("no-scroll")
  }

  function enableScrolling(){
    var element = document.getElementsByTagName("html")[0]
    element.classList.remove("no-scroll")
  }

  function getPagadorArcoprime(token) {
    const url = process.env.REACT_APP_PEDIDOS_API + "/pedidos/eds-portal";

    let pagador = ''

    fetch(url, { headers: { token: token } })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.code === 200){
            if(result.data.user_eds){
              pagador = result.data.user_eds[0]

              if(pagador && pagador.length === 10){
                pagador = pagador.substring(5, 10)
              }
              pagador = '1' + pagador
              setPagadorArcoprime(pagador)
            }
          }
        }
      );

      return pagador
  }

  return (
    <>
      <div className="App">
        {showLoader && <Loader />}
        {impersonatedUser &&
          <>
            <div className={`impersonated-user-band ${isROImpersonate? 'impersonated-user-band-ro': ''}`}>
              <div className="band-message">
                <b>VISTA USUARIO:</b>&nbsp;&nbsp;&nbsp;{ impersonatedUser }&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span onClick={() => setModalIsOpen(true)} className="band-message-exit">SALIR</span>
              </div>
            </div>
            <div className={`impersonated-user-band-fixed ${isROImpersonate? 'impersonated-user-band-fixed-ro': ''}`}>
              <div className="band-message">
                <b>VISTA USUARIO:</b>&nbsp;&nbsp;&nbsp;{ impersonatedUser }&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span onClick={() => setModalIsOpen(true)} className="band-message-exit">SALIR</span>
              </div>
            </div>
            {/*
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div className="impersonate-modal-content">
                <img className="close-modal" onClick={closeModal} src={closeIcon} />
                <div className="modal-info">
                  <img className="modal-central-icon" src={importante} />
                  <div className="title">Importante</div>
                  ¿Desea salir del modo {isROImpersonate? 'visualización': 'suplantación'} de usuario?
                  <hr />
                  <button className="modal-button accept" onClick={() => removeImpersonate(token)}>Aceptar</button>
                  <button className="modal-button cancel" onClick={closeModal}>Cancelar</button>  
                </div>
              </div>
            </Modal>
            */
          }
          </>
        }
        
        <Alert
          displayModal={displayCustomAlert}
          closeModal={closeCustomAlert}
          modalTitle={alertTitle}
          modalText={alertText}
          modalIcon={alertIcon}
          acceptFunc={closeCustomAlert}
          acceptText="Aceptar"
          showGreyBackground={null}
          hideGreyBackground={null}
          scrollTo={scrollTo}
          disableScrolling={disableScrolling}
          enableScrolling={enableScrolling}
          pageYOffset={pageYOffset}
          isDesktopRef={isDesktopRef.current}
        />

        <Alert
          displayModal={modalIsOpen}
          closeModal={closeModal}
          modalTitle='Importante'
          modalText={`¿Desea salir del modo ${isROImpersonate? 'visualización': 'suplantación'} de usuario?`}
          modalIcon='importante'
          acceptFunc={removeImpersonate}
          cancelFunc={closeModal}
          acceptText="Aceptar"
          showGreyBackground={null}
          hideGreyBackground={null}
          scrollTo={scrollTo}
          disableScrolling={disableScrolling}
          enableScrolling={enableScrolling}
          pageYOffset={pageYOffset}
          isDesktopRef={isDesktopRef.current}
        />
          <header>
            {width > breakpoint
            ? <NavBar menu={filteredMenu} showMyAccount={showMyAccount} />
            : <NavBarMobile menu={filteredMenu} showMyAccount={showMyAccount} />
            }
          </header>
          {token && user &&
              <div className="content-container">
                <Routes>
                  <Route exact path='/menu-empty' element={<PrivateRoute path='/menu-empty' />}>
                    <Route exact path='/menu-empty' element={<EmptyMenu/>}/>
                  </Route>

                  <Route exact path='/profile' element={<PrivateRoute path='/profile' />}>
                    <Route 
                      exact 
                      path='/profile' 
                      element={
                        <Profile
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                        />
                      }/>
                  </Route>

                  <Route exact path="/search/:query"  element={<PrivateRoute path="/search/:query"  />}>
                    <Route 
                      exact 
                      path="/search/:query"  
                      element={
                        <SearchResults
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-comunicados/:path/:id" element={<PrivateRoute path="/c-comunicados/:path/:id" />}>
                    <Route 
                      exact 
                      path="/c-comunicados/:path/:id"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="full"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_CONTENTS_URL + "/comunicados/:path/:id"
                          }
                          token={token}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-postulante/:id" element={<PrivateRoute path="/c-postulante/:id" />}>
                    <Route 
                      exact 
                      path="/c-postulante/:id"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="normal"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_WORKFLOW_URL + "/postulante/:id"
                          }
                          token={token}
                          user={user}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-postulantes" element={<PrivateRoute path="/c-postulantes" />}>
                    <Route 
                      exact 
                      path="/c-postulantes"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="normal"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_WORKFLOW_URL + "/postulaciones"
                          }
                          token={token}
                          user={user}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-faq/:path/:id" element={<PrivateRoute path="/c-faq/:path/:id"  />}>
                    <Route 
                      exact 
                      path="/c-faq/:path/:id"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="normal"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_CONTENTS_URL + "/faq/:path/:id"
                          }
                          token={token}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-procedimientos/:path/:id" element={<PrivateRoute path="/c-procedimientos/:path/:id" />}>
                    <Route 
                      exact 
                      path="/c-procedimientos/:path/:id"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="normal"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_CONTENTS_URL + "/procedimientos/:path/:id"
                          }
                          token={token}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/c-organigramas/:path" element={<PrivateRoute path="/c-organigramas/:path" />}>
                    <Route 
                      exact 
                      path="/c-organigramas/:path"
                      element={
                        <MicroComponent
                          displaySidebar={false}
                          displayFloatingSidebar={false}
                          componentWidth="full"
                          iframeClicked={iframeClicked}
                          menu = { filteredMenu }
                          bucketPath={
                            process.env.REACT_APP_CONTENTS_URL + "/organigramas/:path"
                          }
                          token={token}
                          pagadorArcoprime={pagadorArcoprime}
                          isROImpersonate={isROImpersonate}
                        />
                      }/>
                  </Route>

                  <Route exact path="/mi-cuenta" element={<PrivateRoute path="/mi-cuenta" />}>
                    <Route 
                      exact 
                      path="/mi-cuenta"
                      element={
                        <MyAccount
                          displaySidebar={false}
                          displayFloatingSidebar={false}                  
                        />
                      }/>
                  </Route>

                  <Route exact path="/reporte" element={<PrivateRoute path="/reporte" />}>
                    <Route 
                      exact 
                      path="/reporte"
                      element={
                        <Report
                          token={token}                
                        />
                      }/>
                  </Route>

                  {Object.keys(menu).map((key) =>
                    Object.keys(menu[key].children).map((menukey) =>
                      Object.keys(
                        menu[key].children[menukey].children
                      ).map((menukeylast) => (
                        <Route exact path={getPath(key, menukey, menukeylast)} element={<PrivateRoute path={getPath(key, menukey, menukeylast)} />}>
                          <Route exact path={getPath(key, menukey, menukeylast)} 
                            element={
                              <MicroComponent
                                bucketPath={
                                  menu[key].children[menukey].children[menukeylast].componentUrl
                                }
                                token={token}
                                pagadorArcoprime={pagadorArcoprime}
                                displaySidebar={
                                  menu[key].children[menukey].children[menukeylast].sidebarMenu
                                }
                                componentWidth={
                                  menu[key].children[menukey].children[menukeylast].width
                                }
                                menuKey={key}
                                menuSidebar={menu[key].children}
                                menuSecondLevel={menukey}
                                menuThirdLevel={menukeylast}
                                iframeClicked={iframeClicked}
                                isAdmin={key === "Administración" ? true : false}
                                type={menu[key].children[menukey].children[menukeylast].type}
                                user={user}
                                isROImpersonate={isROImpersonate}
                              />
                            }/>
                        </Route>    
                      ))
                    )
                  )}

                  <Route exact path="/" element={<PrivateRoute path="/" />}>
                    <Route 
                      exact 
                      path="/"
                      element={
                        <Home
                          token={token}
                          setAlertText={setAlertText}
                          setAlertTitle={setAlertTitle}
                          setAlertIcon={setAlertIcon}
                          setDisplayCustomAlert={setDisplayCustomAlert}                
                        />
                      }/>
                  </Route>

                  <Route path="" element={<PrivateRoute path="" />}>
                    <Route 
                      path=""
                      element={
                        <EmptyMenu
                        />
                      }/>
                  </Route>
                </Routes>
              </div>
            }
            <footer>
              <Footer />
            </footer>
      </div>

      <div className="landscape-message">
        Vista no disponible
        <div className="subtitle">
          Vuelve a girar tu dispositivo al modo vertical.
        </div>
      </div>
    </>
  );
}

export default App;
