import React, { Fragment, useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";

import "../styles/home.scss";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";

function HomeSection({ data, 
  reversed, 
  small, 
  redCta, 
  insideCarousel, 
  mobile
}) {
  const { title, lead, button_text, image, link } = data;
  let rootClassName = "";
  let content = "";
  let homeTitle = "";
  let homeDescription = "";
  let homeLink = "";
  let homeImg = "";
  if (mobile) {
    rootClassName = "mobile";
    content = "mobile-content";
    homeTitle = "mobile-title";
    homeDescription = "mobile-description";
    homeLink = "mobile-link cta";
    homeImg = "mobile-img";
  } else {
    rootClassName = "home-section";
    content = "home-section__content";
    homeTitle = "home-section__title";
    homeDescription = "home-section__description";
    homeLink = "home-section__link cta";
    homeImg = "home-section__img";
    if (reversed) {
      rootClassName = rootClassName + " reversed";
    }
    if (small) {
      rootClassName = rootClassName + " small";
    }
    if (insideCarousel) {
      rootClassName = rootClassName + " in-carousel";
    }
  }
  if (redCta) {
    rootClassName = rootClassName + " red-cta";
  }
  const symbol = () => <span>&#10140;</span>
  return (
    <div className={rootClassName}>
      <img
        src={`${process.env.REACT_APP_ASSETS_BASE_URL}${image}`}
        alt={title}
        className={homeImg}
      />
      <div className={content}>
        <p className={homeTitle}>{title}</p>
        <p className={homeDescription}>{lead}</p>
        <a className={homeLink} href={link}>
          {button_text} {redCta && mobile && symbol()}
        </a>
      </div>
    </div>
  );
}

function Carousel({ items, mobile }) {
  return (
    <CarouselProvider
      isIntrinsicHeight
      totalSlides={items.length}
      className="home-carousel"
      isPlaying
      interval='8000'
    >
      <Slider>
        {items.map((item, i) => (
          <Slide key={item.title} index={i}>
            <HomeSection
              data={item}
              redCta
              reversed={i % 2 !== 0}
              insideCarousel
              mobile={mobile}
            />
          </Slide>
        ))}
      </Slider>
      <DotGroup />
    </CarouselProvider>
  );
}

const Home = ({ token,
  setAlertText,
  setAlertTitle,
  setAlertIcon,
  setDisplayCustomAlert
}) => {
  const { signinRedirect } = useAuth();
  const [ content, setContent ] = useState()
  const [ metadata, setMetadata ] = useState()
  const [width, setWidth] = useState(window.innerWidth);
  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if(token){
      getHomeContents()
    }
  }, [token]);

  async function getHomeContents() {
    const url = process.env.REACT_APP_BASE_URL + "contents/contents?type=home&path=home-path";
    let fetch_init = { headers: { token: token }};
    
    if(process.env.REACT_APP_IS_COPEC === 'true'){
      fetch_init['credentials'] = "include";
    }
   
    await fetch(url, fetch_init)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && result.data && result.data.length > 0) {
            setContent(result.data[0])
            if(result.data[0]){
              setMetadata(result.data[0].metadata)
            }
          } else {
            setAlertText("Ha ocurrido un error. Por favor contáctate con el administrador.")
            setAlertTitle("Error")
            setAlertIcon("error")
            setDisplayCustomAlert(true)
          }
        },
        (error) => {
        }
      );
  }

  return (
    <div className={`content-container home ${token ? "logged-in" : ""}`}>
      {!!token ? (
        metadata && (
          <Fragment>
            <Carousel items={metadata.carousel} mobile={width < breakpoint}/>
            <div className="news-wrapper"> 
              <HomeSection 
                data={metadata.news}
                small
                mobile={width < breakpoint}
              />
            </div>
          </Fragment>
        )
      ) : (
        <button
          className="login-button cta"
          onClick={() => signinRedirect()}
        >
          Log in
        </button>
      )}
    </div>
  );
};

export default Home;
