import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
//import history from "../utils/history";
import Loader from "./Loader";
import Breadcrumbs from "./Breadcrumbs";
import "../styles/micro-component.scss";
import proxyStyles from '../styles/proxy-styles.txt';

import FloatingSideBar from "./FloatingSideBar";
import { getPath } from "../utils/getPath.js";

const MicroComponent = ({
  displaySidebar,
  displayFloatingSidebar = false,
  menuKey,
  menu,
  menuSidebar,
  componentWidth,
  isAdmin,
  menuSecondLevel,
  menuThirdLevel,
  type,
  match,
  bucketPath,
  token,
  iframeClicked,
  user,
  pagadorArcoprime,
  isROImpersonate
}) => {
  //const CreateProfile = ({onFirstNameChange, onHide, show }) => {...}
  const [showLoader, setShowLoader] = useState(false);
  const [iframeHeight, setIframeHeight] = useState("600px");
  const [iframeSrcProxy, setIframeSrcProxy] = useState();
  const [showGreyBackground, setShowGreyBackground] = useState(false);
  const [showModalGreyBackground, setShowModalGreyBackground] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate()
  const locationOriginal = useLocation()
  const locationRef = useRef();
  //const [ iframeSrc, setIframeSrc ] = useState(bucketPath)
  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //bucketPath = "https://qa.copec.cl/wasapp/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=RUT_EMPRESA&empresaRut=RUT_EMPRESA&SSORutEmpresa=RUT_EMPRESA&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = bucketPath.replace(/RUT_EMPRESA/g, user.rut_concesionario);
  //let iframeSrc = bucketPath.replace(/RUT_EMPRESA/g, '4172197-9');

  let iframeSrc = bucketPath;
  //let iframeSrc = "http://localhost:3001/resumen"; //cta corriente
  //let iframeSrc = "http://localhost:3001/cartola"; //cta corriente
  //let iframeSrc = "http://localhost:3001/pagos"; //cta corriente
  //let iframeSrc = "http://localhost:3001/historial-pagos"; //cta corriente
  //let iframeSrc = "http://localhost:3001/liberar-pagos"; //cta corriente
  //let iframeSrc = "http://localhost:3001/abonar-en-banco"

  //let iframeSrc = "http://localhost:3001/historial-fluctuaciones"

  //let iframeSrc = "http://localhost:3001/historial-de-modificaciones"

  //let iframeSrc = "http://localhost:3001/fluctuaciones-ingreso-mediciones"
  //let iframeSrc = "http://localhost:3001/historial-de-alarmas"
  //let iframeSrc = "http://localhost:3001/configuracion-de-alarmas"
  //let iframeSrc = "http://localhost:3001/configuracion-tanques-concesionario"
  //let iframeSrc = "http://localhost:3001/configuracion-tanques-telesoporte"

  //let iframeSrc = "https://app.workflow.copec.pharedata.com/reporte"
  //let iframeSrc = "http://localhost:4200/dashboard";
  //let iframeSrc = "http://localhost:3001/contents/comunicados/test2/1605138092921";

  //let iframeSrc = "http://localhost:3001/contents/comunicados/comunicados-1603748990191";
  //let iframeSrc = "http://localhost:3001/contents/comunicados/comunicados-1603748990191/1612224974043"
  //let iframeSrc = "http://localhost:3001/contents/comunicados/comunicados-1603748990191/1612224974043"
  //let iframeSrc = "http://localhost:3001/contents/comunicados/comunicados-1603748990191/1612224974043"

  //let iframeSrc = "http://localhost:3001/contents/comunicados/comunicados-1603748985190";

  //let iframeSrc = "http://localhost:3001/contents/procedimientos/new-data/1600454392912";
  //let iframeSrc = "http://localhost:3001/contents/procedimientos/new-data";

  //let iframeSrc = "http://localhost:3001/contents/procedimientos/test-new-title";
  //let iframeSrc = "http://localhost:3001/contents/faq-admin";
  //let iframeSrc = "http://localhost:3001/contents/organigramas-admin";
  //let iframeSrc = "http://localhost:3001/contents/procedimientos-admin";
  //let iframeSrc = "http://localhost:3001/contents/comunicados-admin";
  //let iframeSrc = "http://localhost:3001/contents/home-admin";
  //let iframeSrc = "http://localhost:3001/identities/menu";
  //let iframeSrc = "http://localhost:3001/identities/users";
  //let iframeSrc = "http://localhost:3001/identities/roles";
  //let iframeSrc = "http://localhost:3001/identities/groups";
  //let iframeSrc = "http://localhost:3001/identities/resources";
  //let iframeSrc = "http://localhost:3001/identities/impersonate";
  //let iframeSrc = "http://localhost:3001/identities/impersonate-ro";

  //let iframeSrc = "http://localhost:3001/contents/organigramas/Test";
  //let iframeSrc = "http://localhost:3001/contents/organigramas-admin";

  //let iframeSrc = "http://localhost:3001/contents/faq/covid/1602113105359";
  //let iframeSrc = "http://localhost:3001/contents/faq/covid";
  //let iframeSrc = "http://localhost:4200/dashboard";
  //let iframeSrc = "http://localhost:4200/payments";
  //let iframeSrc = "http://localhost:4200/search";
  //let iframeSrc = "http://localhost:4200/search/pronto";
  //let iframeSrc = "http://localhost:4200/search-ffaa";
  //let iframeSrc = "http://localhost:4200/dashboard-ffaa";
  //let iframeSrc = "http://localhost:4200/dashboard-muevo";
  //let iframeSrc = "http://localhost:4200/search-muevo";
  
  //let iframeSrc = "http://localhost:3001/ot-cobros-por-formular"
  //let iframeSrc = "http://localhost:3001/avisos-de-mantenimiento"

  //let iframeSrc = "http://localhost:3001/cierre-diario"
  //let iframeSrc = "http://localhost:3001/historial-fluctuaciones"
  //let iframeSrc = "http://localhost:3001/test"
  //let iframeSrc = "http://localhost:3001/cierre-mensual"
  //let iframeSrc = "http://localhost:3001/bluemax"
  //let iframeSrc = "http://localhost:3001/lubricantes"
  //let iframeSrc = "http://localhost:3001/bluemax-detail/this-is-the-bluemax-type"

  //let iframeSrc = "http://localhost:3001/regularizacion"
  //let iframeSrc = "http://localhost:3001/historial-regularizacion"
  
  //let iframeSrc = "https://portalconcesionarios-api-qa.copec.cl/portal/proxy/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=76249988-6&empresaRut=76249988-6&SSORutEmpresa=76249988-6&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = "https://app.portalconcesionarios.copec.pharedata.com/portal/proxy/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=76249988-6&empresaRut=76249988-6&SSORutEmpresa=76249988-6&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = "https://qa.copec.cl/wasapp/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=RUT_EMPRESA&empresaRut=RUT_EMPRESA&SSORutEmpresa=RUT_EMPRESA&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = "https://api.portalconcesionarios.copec.pharedata.com/portal/proxy/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=76249988-6&empresaRut=76249988-6&SSORutEmpresa=76249988-6&SSOUsuarioID=746060&DEUDOR=0"

  //let iframeSrc = "https://qa.copec.cl/wasapp/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=RUT_EMPRESA&empresaRut=RUT_EMPRESA&SSORutEmpresa=RUT_EMPRESA&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = "https://qa.copec.cl/wasapp/CopecWebPortalConM8/Concesionarios/bienvenida.do?method=viewEstacionServicio&SSOEmpresaRut=RUT_EMPRESA&empresaRut=RUT_EMPRESA&SSORutEmpresa=RUT_EMPRESA"

  //let iframeSrc = "https://copec.cl/wasapp/CopecWebPortalConM7/liquidacion/menuLiquidacion.do?method=despliegueMenuLiquidacion&SSOEmpresaRut=76567598-7&empresaRut=76567598-7&SSORutEmpresa=76567598-7&SSOUsuarioID=746060&DEUDOR=0"
  //let iframeSrc = "https://copec.cl/wasapp/CopecWebPortalConM8/Concesionarios/bienvenida.do?method=viewEstacionServicio&SSOEmpresaRut=76567598-7&empresaRut=76567598-7&SSORutEmpresa=76567598-7"

  //WORKFLOW
  //let iframeSrc = "http://localhost:3001/postulaciones";
  //let iframeSrc = "http://localhost:3001/users";
  //let iframeSrc = "http://localhost:3001/postulante/872d62e2-1b50-473b-8900-2a1ebafb37b3";
  //let iframeSrc = "http://localhost:3001/reporte-postulantes";
  //let iframeSrc = "http://localhost:3001/reporte";
  //let iframeSrc = "http://localhost:3001/form";
  //let iframeSrc = "http://localhost:3001/rechazo/22-0";
  //let iframeSrc = "http://localhost:3001/reporte";

  //PEDIDOS
  //let iframeSrc = "http://localhost:3001/formulario-pedidos"
  //let iframeSrc = "http://localhost:3001/pedidos-activos"
  //let iframeSrc = "http://localhost:3001/historial-pedidos"

  //PORTAL PAGOS
  //let iframeSrc = "http://localhost:3001/pagos?jwt=REPLACE_TOKEN"
  //let iframeSrc = "http://localhost:3001/liberar-documentos?jwt=REPLACE_TOKEN"
  //let iframeSrc = "http://localhost:3001/resumen-cuenta-corriente?jwt=REPLACE_TOKEN"
  //let iframeSrc = "http://localhost:3001/abonos?jwt=REPLACE_TOKEN"
  //let iframeSrc = "http://localhost:3001/cartola?jwt=REPLACE_TOKEN"
  //let iframeSrc = "http://localhost:3001/historial-pagos?jwt=REPLACE_TOKEN"

  //COMISIONES
  //let iframeSrc = "http://localhost:3001/auditoria";
  //let iframeSrc = "http://localhost:3001/ajustar-variables";
  //let iframeSrc = "http://localhost:3001/historial-ajustes";
  //let iframeSrc = "http://localhost:3001/inhabilitar-gastos"
  //let iframeSrc = "http://localhost:3001/alertas";
  //let iframeSrc = "http://localhost:3001/activacion-eds";
  //let iframeSrc = "http://localhost:3001/revision-pago"  
  //let iframeSrc = "http://localhost:3001/revisar-ajustes"
  //let iframeSrc = "http://localhost:3001/revision"

  //TCT-TAE
  //let iframeSrc = "http://localhost:3002/autorizacion-consumo-tarjetas"
  //let iframeSrc = "http://localhost:3002/consultar-guias-manuales"
  //let iframeSrc = "http://localhost:5173/transacciones-por-producto"
  //let iframeSrc = "http://localhost:5173/trx-producto"
  //let iframeSrc = "http://localhost:3002/trx-producto"

  //COMISIONES 2
  //let iframeSrc = "http://localhost:5173/comisiones-test"
  //let iframeSrc = "http://localhost:5173/consignacion"
  //let iframeSrc = "http://localhost:5173/fluctuaciones"
  //let iframeSrc = "http://localhost:5173/comercializacion"  

  //let iframeSrc = "http://localhost:3001/ordenes-compra"
  //let iframeSrc = "http://localhost:5173/lista-ordenes-compra/4502481455"

  //CONCILIACION
  //let iframeSrc = "http://localhost:3001/combustible"
  //componentWidth = "full"

  //POA
  //let iframeSrc = "http://localhost:3001/venta-mensual"
  //componentWidth = "full"

  //PREFACTURA
  //let iframeSrc = "http://localhost:3001/prefactura"
  //let iframeSrc = "http://localhost:3001/configuracion-servicios"
  

  if(type === 'external-content'){
    //test: 4172197-9

    if (user.rut_concesionario === "77215640-5") { // If is Arcoprime
      iframeSrc = iframeSrc.replace(/RUT_EMPRESA/g, pagadorArcoprime);
    }
    else{
      iframeSrc = iframeSrc.replace(/RUT_EMPRESA/g, user.rut_concesionario);
    }

    const rutArr = user.metadata.rut

    if(rutArr){
      const rutSinDigito = rutArr.split("-")[0]
      iframeSrc = iframeSrc.replace(/RUT_USUARIO/g, rutSinDigito);
    }

    //iframeSrc = iframeSrc.replace(/RUT_EMPRESA/g, '4172197-9');
    //iframeSrc = iframeSrc.replace(/RUT_EMPRESA/g, '76249988-6');
  }

  iframeSrc = iframeSrc.replace(/REPLACE_TOKEN/g, token);

  useEffect(() => {
    document
      .getElementById("micro-component-window")
      .contentWindow.postMessage({ isROImpersonate: isROImpersonate }, "*");
  }, [isROImpersonate])

  function checkIframeLoaded() {
      // Get a handle to the iframe element
      let iframeElement = document.getElementById("micro-component-window");

      if(iframeElement){
        let iframeDoc = iframeElement.contentDocument || iframeElement.contentWindow.document;

        // Check if loading is complete
        if (  iframeDoc.readyState  == 'complete' ) {
          window.setTimeout(testStylesChange, 2000);
          window.setTimeout(testStylesChange, 4000);
          window.setTimeout(testStylesChange, 12000);
          iframeElement.contentWindow.onload = function(){
          };
          // The loading is complete, call the function we want executed once the iframe is loaded
          return;
        }
      }

      // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
      window.setTimeout(checkIframeLoaded, 200);
  }

  function testStylesChange(){
    let iframeElement = document.getElementById("micro-component-window");
    let iframeDoc = iframeElement.contentDocument  || iframeElement.contentWindow.document
    fetch(proxyStyles)
      .then((r) => r.text())
      .then(text  => {
        iframeDoc.body.innerHTML = iframeDoc.body.innerHTML + '<style>' + text + '</style>';
      })
  }

  let { id, path } = useParams();
  if(id)
    iframeSrc = iframeSrc.replace(":id", id);

  if(path)
    iframeSrc = iframeSrc.replace(":path", path);
/*
  for (let key in match.params) {
    iframeSrc = iframeSrc.replace(":" + key, match.params[key]);
  }
*/
  async function formSubmissionFromIframe(url, params) {
    const formData = new FormData();

    setShowLoader(true);

    await fetch(url, {
      method: "POST",
      headers: {
        token: token,
      },
      body: params,
    })
      .then((res) => res.text())
      .then(
        (result) => {
          document
            .getElementById("micro-component-window")
            .contentWindow.postMessage({ iframeContentChange: result }, "*");
        },
        (error) => {
        }
      );

    setShowLoader(false);
  }

  async function callProxy() {
    setShowLoader(true)

    await fetch(bucketPath, { headers: { token: token } })
      .then((res) => {
        if(res.status !== 200)
          throw new Error("HTTP status " + res.status)

        return res.text()
      })
      .then((result) => {
        fetch(proxyStyles)
          .then((r) => r.text())
          .then(text  => {
            result = result.replace('</head>','<style>' + text + '</style></head>')
            setIframeSrcProxy(result)
          })
      },
      (error) => {
        setIframeSrcProxy('<html><h1>' + error.toString() + '</h1></html>')
      }
    );

    setShowLoader(false)
  }

  const receiveMessage = (event) => {
    const message = event.data;
    const location = {... locationRef.current}

    if (message.getToken) {
      if (token) {
        let iframeParams = location.search;
        iframeParams = iframeParams.replace("?", "");
        document
          .getElementById("micro-component-window")
          .contentWindow.postMessage({ params: iframeParams }, "*");

        //const token = resp.__raw;
        document
          .getElementById("micro-component-window")
          .contentWindow.postMessage({ token: token }, "*");

        if (type === "proxy") {
          document
            .getElementById("proxy-service-worker-window")
            .contentWindow.postMessage({ token: token }, "*");
        }
      }
      else{
        document
          .getElementById("micro-component-window")
          .contentWindow.postMessage({ token: false }, "*");

      }
    }

    if(message.getUser) {
      if(user){
        document
          .getElementById("micro-component-window")
          .contentWindow.postMessage({ user: user }, "*");
      }
      else{
        document
          .getElementById("micro-component-window")
          .contentWindow.postMessage({ user: false }, "*");
      }
    }

    if(message.getIsROImpersonate){
      document
      .getElementById("micro-component-window")
      .contentWindow.postMessage({ isROImpersonate: isROImpersonate }, "*");
    }

    if (message.setParams) {
      const params = message.setParams;
      const search = location.search.replace("?", "");

      if (params !== search) {
        location.search = params;
        navigate(location);
        //window.location.reload();
      }
    }

    if (message.showLoader) {
      setShowLoader(true);
    }

    if (message.hideLoader) {
      setShowLoader(false);
    }

    if (message.showGreyBackground) {
      setShowGreyBackground(true);
    }

    if (message.hideGreyBackground) {
      setShowGreyBackground(false);
    }

    if (message.showModalGreyBackground) {
      setShowModalGreyBackground(true);
    }

    if (message.hideModalGreyBackground) {
      setShowModalGreyBackground(false);
    }

    if (message.goBack) {
      navigate(-1)
      //window.location.reload();
    }

    if (message.scrollTo) {
      window.scrollTo(message.scrollTo.x, message.scrollTo.y);
    }

    if(message.getPageYOffset){
      document
        .getElementById("micro-component-window")
        .contentWindow.postMessage({ pageYOffset: window.pageYOffset }, "*");
    }

    if (message.heightChange) {
      if (Number(message.heightChange) < 600) {
        setIframeHeight("600px");
      } else {
        setIframeHeight(message.heightChange.toString() + "px");
      }
    }

    if (message.redirect) {
      let currentUrl = location.pathname;
      if (
        location.search !== undefined &&
        location.search !== ""
      ) {
        currentUrl = currentUrl + location.search;
      }

      if (currentUrl !== message.redirect) {
        navigate(message.redirect)
      }
    }

    if (message.reloadHome) {
      window.location.href = "/";
    }

    if (message.formSubmissionFromIframe) {
      formSubmissionFromIframe(
        message.formSubmissionFromIframe.url,
        message.formSubmissionFromIframe.params
      );
    }

    if (message.getViewPortHeight) {
      document
        .getElementById("micro-component-window")
        .contentWindow.postMessage({ viewPortHeight: window.innerHeight }, "*");
    }
  };

  useEffect(() => {
    // Detectar click en iframe y cerrar menu
    window.addEventListener("blur", function () {
      if (document.activeElement.id === "micro-component-window") {
        iframeClicked();
      }
    });

    window.addEventListener("message", receiveMessage, true);

    document
      .getElementById("micro-component-window")
      .contentWindow.addEventListener('onpopstate', function(event) {
        navigate(-1)
      }, true);

    if (type !== "proxy") {
      let url = iframeSrc;

      if (url.includes(":path") || url.includes(":id")) {
        url = url.replace(":path", path);
        url = url.replace(":id", id);
      }

      iframeSrc = url;
    } else {
      callProxy();
    }
/*
    return navigate.listen((location) => {
      const params = location.search.replace("?", "");
      document
        .getElementById("micro-component-window")
        .contentWindow.postMessage({ params: params }, "*");
    });
*/
  }, []);

  useEffect(() => {
    // triggered on route change
    locationRef.current = locationOriginal
    const params = locationOriginal.search.replace("?", "");
    document
      .getElementById("micro-component-window")
      .contentWindow.postMessage({ params: params }, "*");
  }, [locationOriginal])

  return (
    <div className={`micro-component-container ${(showGreyBackground || showModalGreyBackground) ? "grey-background-visible" : ""}`}>
      <div className={(showGreyBackground || showModalGreyBackground) ? "grey-background" : ""} />
      {showLoader && <Loader />}
      <div className="restricted-width">
        <Breadcrumbs
          menu={menu}
          menuFirstLevel={menuKey}
          menuSecondLevel={menuSecondLevel}
          menuThirdLevel={menuThirdLevel}
          iframeSrc={iframeSrc}
        />
      </div>
      <div
        className={
          componentWidth === "full" || width < breakpoint
            ? "full-width"
            : "restricted-width"
        }
      >
        {displayFloatingSidebar && (
          <FloatingSideBar
            menuKey={menuKey}
            menuSidebar={menuSidebar}
            isAdmin={isAdmin}
            menuSecondLevel={menuSecondLevel}
            menuThirdLevel={menuThirdLevel}
          />
        )}
        {displaySidebar && (
          <div className={width > breakpoint ? "row" : "column"}>
            <div className="iframe-column">
              {type !== "proxy" && type !== 'external-content' &&
                <iframe
                  key={iframeSrc}
                  id="micro-component-window"
                  title="Microcomponente Copec"
                  frameBorder="0"
                  height={iframeHeight}
                  src={iframeSrc}
                  scrolling="no"
                />
              }
              {type === "proxy" &&
                <>
                  <iframe
                    key={iframeSrc}
                    id="micro-component-window"
                    title="Microcomponente Copec"
                    frameBorder="0"
                    height={iframeHeight}
                    srcDoc={iframeSrcProxy}
                    scrolling="no"
                  />
                  <iframe
                    key={iframeSrc}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    id="proxy-service-worker-window"
                    title="Proxy Service Worker"
                    frameBorder="0"
                    height="0"
                    src="https://portalconcesionarios-api-qa.copec.cl/portal/proxy/load-sw"
                    scrolling="no"
                  />
                </>
              }
              {type === "external-content" &&
                <iframe
                  key={iframeSrc}
                  id="micro-component-window"
                  title="Microcomponente Copec"
                  frameBorder="0"
                  height="1000px"
                  src={iframeSrc}
                  scrolling="yes"
                />
              }
            </div>
          </div>
        )}
        {!displaySidebar && type !== "proxy" && type !== 'external-content' && (
          <iframe
            key={iframeSrc}
            id="micro-component-window"
            title="Microcomponente Copec"
            width="100%"
            height={iframeHeight}
            frameBorder="0"
            src={iframeSrc}
            scrolling="no"
          />
        )}
        {!displaySidebar && type === "proxy" &&
          <>
            <iframe
              key={iframeSrc}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              id="micro-component-window"
              title="Microcomponente Copec"
              width="100%"
              height={iframeHeight}
              frameBorder="0"
              srcDoc={iframeSrcProxy}
              scrolling="yes"
              seamless
            />
            <iframe
              key={iframeSrc}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              id="proxy-service-worker-window"
              title="Proxy Service Worker"
              frameBorder="0"
              height="0"
              src="https://portalconcesionarios-api-qa.copec.cl/portal/proxy/load-sw"
              scrolling="no"
            />
          </>
        }
        {!displaySidebar && type === "external-content" &&
          <iframe
            key={iframeSrc}
            id="micro-component-window"
            title="Microcomponente Copec"
            width="100%"
            height="1000px"
            frameBorder="0"
            src={iframeSrc}
            scrolling="yes"
          />
        }
      </div>
    </div>
  );
};

export default MicroComponent;
