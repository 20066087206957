import React, { useState, Fragment } from 'react';
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import logo from '../assets/img/logo_rojo.svg';
import link_icon from '../assets/img/link_icon.svg';
import lupa from '../assets/img/magnifier_icon.svg';
import config from '../assets/img/config_icon.svg';
import config_active from '../assets/img/config_active_icon.png';
import caretDown from '../assets/img/caret_down.svg';
import caretDownBlue from '../assets/img/caret-down-blue.svg';
import usuario from '../assets/img/user_icon.svg';
import Modal from 'react-modal';
import Search from './Search';
import { getPath } from '../utils/getPath.js';
import onClickOutside from "react-onclickoutside";
//import '../styles/nav-bar.scss';

import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const NavBar = ({ menu, showMyAccount }) => {
  const { isAuthenticated, signinRedirect, signoutRedirect, removeUser } = useAuth();
  const [ modalIsOpen, setIsOpen ] = useState(false);
  const [ userOpen, setUserOpen ] = useState(false)
  const [ firstLevelOpen, setFirstLevelOpen ] = useState()

  NavBar.handleClickOutside = () => 
  {
    closeNavBar()
  }

  function sortkeysByOrder(obj) {
    return Object.keys(obj).sort((a, b) => (obj[a].order > obj[b].order) ? 1 : -1)
  }

  function openModal() {
    setUserOpen(false)
    setIsOpen(true)
  }
 
  function closeModal(){
    setIsOpen(false)
  }

  function firstLevelClick(key){
    setUserOpen(false)
    setFirstLevelOpen(key)
  }

  function closeNavBar(){
    setUserOpen(false)
    setTimeout(() => {
      setFirstLevelOpen()
    }, 100);
  }

  const handleLogout = () => {
    removeUser()
    signoutRedirect()
  }

  return (
    <div className='header-content'>
      <button
        id="hidden-button-for-closing-menu"
        onClick={() => NavBar.handleClickOutside()}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false} >
        <Search closeModal={closeModal} />
      </Modal>

      <div className='nav-bar'>
        <Link to='/'><img className='logo copec-logo-header' alt='copec-logo' src={logo} /></Link>
        <div className='sections'>
          {
            sortkeysByOrder(menu).map(function(key, index){
              if(menu[key].published && key !== 'Administración'){
                return(
                  <div className={`menu__item ${firstLevelOpen===key? 'open' : ''}`} onClick={() => firstLevelClick(key)} key={key}>
                    <Menu menuButton={
                      <button className="first-level header-menu">{key}<img src={firstLevelOpen===key? caretDownBlue: caretDown} alt='caret-down' /></button>
                    }>
                    {
                      sortkeysByOrder(menu[key].children).map(function(secondKey){
                        return(
                          menu[key].children[secondKey].published &&
                          <SubMenu label={secondKey} className='menu__item-second-level'>
                            {
                              sortkeysByOrder(menu[key].children[secondKey].children).map(function(thirdKey){ 
                                if(menu[key].children[secondKey].children[thirdKey].published){
                                  return(
                                    <>
                                    {
                                      menu[key].children[secondKey].children[thirdKey].type !== 'external-link' &&        
                                        <Link 
                                          onClick={closeNavBar} 
                                          to={getPath(key, secondKey, thirdKey)}
                                        >
                                          <MenuItem className='third-level-menu'>
                                            {thirdKey}
                                          </MenuItem>
                                        </Link>
                                    }
                                    {
                                      menu[key].children[secondKey].children[thirdKey].type === 'external-link' &&
                                        <a
                                          onClick={closeNavBar}
                                          href={menu[key].children[secondKey].children[thirdKey].componentUrl} 
                                          target="_blank" rel="noopener noreferrer"
                                        >
                                          <MenuItem className='third-level-menu'>
                                            {thirdKey}
                                            <img className="link-icon" src={link_icon} alt="link-icon" />
                                          </MenuItem>
                                        </a>
                                    }
                                    </>
                                  )
                                }
                              })                              
                            }
                          </SubMenu>
                        )
                      }) 
                    }
                    </Menu>
                  </div>                  
                )
              }
            })
          }

        </div>

        <div className='actions'>
          <div className='actions-border' />
          <button className='search' onClick={openModal}>
            <img src={lupa} alt='lupa' />
          </button>

          <div className="nav-bar-user actions-icons-header">
            <div className="menu__item">
              <div className="nav-bar-user">
                <div className="user" onClick={() => setUserOpen(!userOpen)}>
                  <img src={usuario} alt='usuario' />
                </div>
              </div>
            </div>
            {
              userOpen &&
              <div
                className="dropdown-menu"
                timeout={900}
              >
                <div className="row users-menu">
                  { showMyAccount &&
                    <div className="column">
                      <Link to="/mi-cuenta" className="profile-link">Mi cuenta</Link>
                    </div>
                  }
                  <div className="column">
                    { isAuthenticated && 
                      <button className="close-session-button" onClick={handleLogout}>Cerrar sesión</button>
                    }
                    {!isAuthenticated &&
                      <button className="close-session-button" onClick={signinRedirect}>Log in</button>
                    }
                  </div>
                </div>
              </div>
            }
          </div>

          { 
            menu['Administración'] &&
            <div className='sections actions-icons-header actions-icons-header-admin'>
              <div className={`menu__item-admin ${firstLevelOpen==='Administración'? 'open' : ''}`} onClick={() => firstLevelClick('Administración')}>
                <Menu menuButton={
                  <img src={config} alt='administracion' />
                }>
                {
                  sortkeysByOrder(menu['Administración'].children).map(function(secondKey){
                    return(
                      menu['Administración'].children[secondKey].published &&
                      <SubMenu label={secondKey} direction='left' className='menu__item-second-level'>
                        {
                          sortkeysByOrder(menu['Administración'].children[secondKey].children).map(function(thirdKey){ 
                            if(menu['Administración'].children[secondKey].children[thirdKey].published){
                              return(
                                <>
                                {
                                  menu['Administración'].children[secondKey].children[thirdKey].type !== 'external-link' &&        
                                    <Link 
                                      onClick={closeNavBar} 
                                      to={getPath('Administración', secondKey, thirdKey)}
                                    >
                                      <MenuItem className='third-level-menu'>
                                        {thirdKey}
                                      </MenuItem>
                                    </Link>
                                }
                                {
                                  menu['Administración'].children[secondKey].children[thirdKey].type === 'external-link' &&
                                    <a
                                      onClick={closeNavBar}
                                      href={menu['Administración'].children[secondKey].children[thirdKey].componentUrl} 
                                      target="_blank" rel="noopener noreferrer"
                                    >
                                      <MenuItem className='third-level-menu'>
                                        {thirdKey}
                                        <img className="link-icon" src={link_icon} alt="link-icon" />
                                      </MenuItem>
                                    </a>
                                }
                                </>
                              )
                            }
                          })                              
                        }
                      </SubMenu>
                    )
                  }) 
                }
                </Menu>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

NavBar.prototype = {}

const clickOutsideConfig = {
  handleClickOutside: () => NavBar.handleClickOutside
};

export default onClickOutside(NavBar, clickOutsideConfig);

