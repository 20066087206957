import React, { Fragment } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";

const Profile = () => {
  const { user, isLoading } = useAuth();

  if (isLoading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <img src={user.picture} alt="Profile" />
      <h2>{user.name}</h2>
      <p>{user.profile.email}</p>
      <code>{JSON.stringify(user, null, 2)}</code>
    </Fragment>
  );
};

export default Profile;