import React from "react";

const EmptyMenu = () => {
  return (
    <div>
    </div>
  );
};

export default EmptyMenu;
