import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import '../styles/report.scss';

const Report = (props) => {
  const [ showLoader, setShowLoader ] = useState(true);
  const [ message, setMessage ] = useState('Generando Reporte');
  const [ subMessage, setSubMessage ] = useState('(Puede tardar varios segundos)');
  const token = props.token;

  useEffect(() => {
    if(token){
      const url = process.env.REACT_APP_BASE_URL + 'reporting/reports';
      fetch(url, {
        method: "POST",
        headers: {
          token: token,
        }
      }).then((res) => res.json())
      .then(
        (result) => {
          getReport(result.data.url);
        },
        (error) => {
          alert(error);
        }
      );
    }
  }, [token]);

  async function getReport(url){
    fetch(url).then(
      (result) => {
        if(result.status === 403){
          setTimeout(function(){ 
            getReport(url); 
          }, 1000);
        }
        else{
          setShowLoader(false);
          setMessage('Reporte Generado');
          setSubMessage();
          window.open(url);
        }
       },
      (error) => {
        setShowLoader(false);
        setMessage(error);
        setSubMessage('Si el error persiste por favor contáctate con el administrador.')
      }
    );
  }

  return (
    <div className='report'>
      { showLoader && <Loader /> }
      <h1>{message}</h1>
      <h2>{subMessage}</h2>
    </div>
  );
};

export default Report;